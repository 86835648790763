import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

Vue.use(Vuetify, {
  iconfont: 'md',
  options: {
    customProperties: true
  },
  theme: {
    primary: '#fbdd3a',
    secondary: '#9DCE4D',
    tertiary: '#9FE8FF',
    yellow: '#FFE27D',
    white: '#FFFFFF'
  },
})
