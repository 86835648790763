import Vue from "vue";
import Router from "vue-router";
const Main = () => import(/* webpackChunkName: "Main" */ "./views/Main.vue");
const Login = () => import(/* webpackChunkName: "Login" */ "./views/Login.vue");
const Register = () =>
  import(/* webpackChunkName: "Register" */ "./views/Register.vue");
const Home = () => import(/* webpackChunkName: "Home" */ "./views/Home.vue");
const NoteSeeding = () =>
  import(/* webpackChunkName: "NoteSeeding" */ "./views/NoteSeeding.vue");
const Statistic = () =>
  import(/* webpackChunkName: "Static" */ "./views/Static.vue");
const StatisticDepartment = () =>
  import(/* webpackChunkName: "Static" */ "./views/StaticDepartment.vue");
const EditProfile = () =>
  import(/* webpackChunkName: "EditProfile" */ "./views/EditProfile.vue");
const PlantKnowledge = () =>
  import(/* webpackChunkName: "PlantKnowledge" */ "./views/PlantKnowledge.vue");
// const Map = () => import(/* webpackChunkName: "Map" */ "./views/Map.vue");
const PlantTop = () =>
  import(/* webpackChunkName: "PlantTop" */ "./views/PlantTop.vue");
// const PlantCompanyStatic = () =>
//   import(/* webpackChunkName: "PlantCompanyStatic" */ "./views/PlantCompanyStatic.vue");
const Project = () =>
  import(/* webpackChunkName: "MainStatistic" */ "./views/Project.vue");
const MainStatistic = () =>
  import(/* webpackChunkName: "MainStatistic" */ "./views/MainStatistic.vue");
const HowtoMap = () =>
  import(/* webpackChunkName: "HowtoMap" */ "./views/HowtoMap.vue");
const Howto = () =>
  import(/* webpackChunkName: "Howto" */ "./views/Howto.vue");
const MainHowto = () =>
  import(/* webpackChunkName: "MainHowto" */ "./views/MainHowto.vue");
const Admin = () =>
  import(/* webpackChunkName: "Admin" */ "./views/admin/Admin.vue");
const Report = () =>
  import(/* webpackChunkName: "Report" */ "./views/admin/Report.vue");
const ReportNursery = () =>
  import(/* webpackChunkName: "ReportNursery" */ "./views/admin/ReportNursery.vue");
// const ReportStudentLoan = () =>
//   import(/* webpackChunkName: "Report" */ "./views/admin/ReportStudentLoan.vue");
const EditPlant = () =>
  import(/* webpackChunkName: "EditPlant" */ "./views/admin/EditPlant.vue");
const MainAdminPage = () =>
  import(/* webpackChunkName: "MainAdminPage" */ "./views/admin/MainAdminPage.vue");
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "*",
      redirect: "/seeding"
    },
    {
      path: "/admin-login",
      component: Admin
    },
    {
      path: "/report_nursery",
      component: MainAdminPage,
      children: [
        {
          path: "/report_nursery",
          component: ReportNursery,
          meta: { isAuthAdmin: true }
        },
        { path: "/report", component: Report, meta: { isAuthAdmin: true } },
        {
          path: "/edit_addplant",
          component: EditPlant,
          meta: { isAuthAdmin: true }
        }
      ]
    },
    {
      path: "/seeding",
      component: Main,
      children: [
        { path: "/login", component: Login },
        { path: "/register", component: Register },
        { path: "/register/:id", component: Register },
        { path: "/project", component: Project },
        { path: "/seeding", component: NoteSeeding, meta: { isAuth: true } },
        {
          path: "/statistic",
          component: MainStatistic,
          children: [
            { path: "/statistic", component: Statistic },
            { path: "/statistic/department", component: StatisticDepartment },
            // { path: "/statistic/map", component: Map },
            { path: "/statistic/plant_top", component: PlantTop }
          ]
        },
        {
          path: "/howto",
          component: MainHowto,
          children: [
            { path: "/howto", component: HowtoMap },
            { path: "/howto/request", component: Howto }, 
          ]
        },
        { path: "/knowledge", component: PlantKnowledge },
        {
          path: "/edit_profile",
          component: EditProfile,
          meta: { isAuth: true }
        }
      ]
    }
  ]
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((x) => x.meta.isAuth2)) {
    if (window.$cookies.isKey("name")) {
      next({ path: "/" });
    } else {
      next();
    }
    return;
  }
  next();
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((x) => x.meta.isAuth)) {
    if (!window.$cookies.isKey("name")) {
      next({ path: "/login" });
    } else {
      next();
    }
    return;
  }
  next();
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((x) => x.meta.isAuthAdmin)) {
    if (!window.$cookies.isKey("admin_name")) {
      next({ path: "/admin-login" });
    } else {
      next();
    }
    return;
  }
  next();
});

export default router;
